<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="companyList-container common-padding"
  >
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>分公司名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>公司状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.status"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option
            v-for="(item, key) in statusSelect"
            :key="key"
            :label="item"
            :value="key + ''"
          ></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属行业:</span>
        <el-select
          class="common-screen-input"
          v-model="params.industry"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option
            v-for="(item, key) in industrySelect"
            :key="key"
            :label="item"
            :value="key"
          ></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>人员规模:</span>
        <el-select
          class="common-screen-input"
          v-model="params.scale"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option
            v-for="(item, key) in scaleSelect"
            :key="key"
            :label="item"
            :value="key"
          ></el-option>
        </el-select>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => commonFun()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => resetBtn()" plain
        >重 置</el-button
      >
    </div>
    <el-button
      class="common-screen-btn common-noMarginLeft"
      type="primary"
      @click="
        () => {
          shareCode();
        }
      "
      >分享邀请码</el-button
    >
    <el-button class="common-screen-btn" type="primary" @click="() => addEdit()"
      >新建分公司</el-button
    >

    <el-table class="common-table" :data="tableData">
      <el-table-column prop="name" label="分公司名称" :formatter="tableColumn">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                showDetail(scope.row);
              }
            "
            >{{ scope.row.name || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="分公司状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == "1" ? "正常" : "停用" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="address_id"
        label="分公司地址"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="industry"
        label="行业类型"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="scale"
        label="人员规模"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_by"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
      ></el-table-column>

      <el-table-column label="板块功能设置">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                setCompany(scope.row);
              }
            "
            >板块编辑</el-button
          >
          <!-- &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="deleteCompany(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <company-detail
      :drawer="drawer"
      ref="companyDetail"
      @handleClose="handleClose"
    ></company-detail>
    <el-dialog
      v-dialogDrag
      :title="tableRow ? '编辑分公司' : '新建分公司'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleFormAdd"
          :rules="rules"
          ref="ruleFormCom"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="公司名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.name"
              maxLength="50"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="address">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.address"
              maxLength="50"
              placeholder="公司地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司状态" prop="status">
            <el-radio v-model="ruleFormAdd.status" label="1">正常</el-radio>
            <el-radio v-model="ruleFormAdd.status" label="-1">停用</el-radio>
          </el-form-item>
          <el-form-item label="板块设置" prop="plateIds">
            <el-checkbox-group v-model="ruleFormAdd.plateIds">
              <el-checkbox
                v-for="(item, i) in comPlateList"
                :key="i"
                :label="item.plateId"
                name="check"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitForm('ruleFormCom');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="分享邀请码"
      class="share-dialog"
      :append-to-body="true"
      :visible.sync="shareVisible"
      width="520px"
    >
      <div class="share-container">
        <div class="share-title">注册链接</div>
        <div class="share-blue-border">{{ urlAdress }}</div>
        <div class="share-title">邀请码</div>
        <div class="share-blue-border">
          <div
            class="share-code-item"
            v-for="(item, index) in shareCodeStr"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              copyShareInfo();
            }
          "
          >复制链接及邀请码</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="板块功能设置"
      class="share-dialog"
      :append-to-body="true"
      :visible.sync="plateVisible"
      width="520px"
    >
      <div class="share-container">
        <el-checkbox-group v-model="setComParams.plateIds">
          <el-checkbox
            v-for="(item, i) in comPlateList"
            :key="i"
            :label="item.plateId"
            name="check"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="plateVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              editTable();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  editCompany,
  deleteCompany,
  comPlate,
  plateEdit,
  companyList,
  shareCode,
  editComPlate
} from "../../service/manage.js";
import { tableColumn, Config } from "../../utils/index.js";
import CompanyDetail from "./children/companyDetail.vue";
export default {
  components: { Breadcrumb, CompanyDetail },
  data() {
    return {
      tableColumn,
      loading: false,
      drawer: false,
      shareVisible: false,
      copyMessage: false,
      centerDialogVisible: false,
      urlAdress: "",
      shareCodeStr: "",
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "分公司设置", isLink: false }
      ],
      statusSelect: { "-1": "停用", "1": "正常" },
      industrySelect: {
        "0": "IT/通信/电子/互联网",
        "1": "金融业",
        "2": "商业服务",
        "3": "贸易/批发/零售/租赁业",
        "4": "文体教育/工艺美术",
        "5": "服务业",
        "6": "文化/传媒/娱乐/体育"
      },
      scaleSelect: {
        "0": "0-50",
        "1": "50-100",
        "2": "100-500",
        "3": "500-1000",
        "4": "1000以上"
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10
        // name: "",
        // companyId: null,
        // roleId: null,
        // userDepartId: null,
      },
      tableRow: "", // 列表item
      tableData: [],
      ruleFormAdd: {
        // 新增编辑form
        name: "",
        address: "",
        status: "1",
        plateIds: []
      },
      rules: {
        name: [
          { required: true, message: "请填写公司名称", trigger: "change" }
        ],
        address: [
          { required: true, message: "请填写公司地址", trigger: "change" }
        ],
        status: [{ required: true, message: "请选择", trigger: "change" }],
        plateIds: [
          {
            type: "array",
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ]
      },
      comPlateList: [],
      params2: {
        companyId: 0
      },
      settDialogVisible: false,
      plateVisible: false,
      setComParams: {
        //配置板块参数
        plateIds: []
      }
    };
  },
  created() {
    this.urlAdress = Config.api.h5Url + "/sign-up";
    this.companyList();
  },
  methods: {
    openHTML() {
      this.$message({
        onClose: () => {},
        dangerouslyUseHTMLString: true,
        showClose: true,
        duration: 1000,
        iconClass: "el-icon-warning-outline",
        customClass: "common-tip-alert",
        message:
          '<div class="tip-title"> <i class="iconfont icon-guanyu"></i> 复制已成功</div><div class="tip-info">粘贴至微信/QQ等，快速邀请服务商加入</div>'
      });
    },
    copyShareInfo() {
      // 复制邀请内容
      let Copytext = `帮客平台邀请您加入帮客智能客服管理系统， 邀请码为【${this.shareCodeStr}】，点击链接立即加入： ${this.urlAdress}`;
      let input = document.createElement("input");
      input.setAttribute("readonly", "readonly");
      input.setAttribute("value", Copytext);
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 9999);
      document.execCommand("Copy");
      if (document.execCommand("Copy")) {
        this.openHTML();
        this.shareVisible = false;
      }
    },
    async shareCode() {
      this.loading = true;
      let { data } = await shareCode();
      this.loading = false;
      this.shareCodeStr = data;
      this.shareVisible = true;
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.companyList();
    },
    handleClose() {
      this.drawer = false;
      this.companyList();
    },
    showDetail(row) {
      this.$refs.companyDetail.getDataInfo(row);
      this.drawer = true;
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRow.id;
      let ruleFormAdd = this.ruleFormAdd;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            let res = await editCompany(ruleFormAdd, tableRowId);
            if (res.code == 200) {
              this.$message.success("修改成功");
            }
          } else {
            let res = await editComPlate(ruleFormAdd);
            if (res.code == 200) {
              this.$message.success("添加成功");
            }
          }
          this.$vuex.dispatch("getMenuInfo");
          this.params.pageNum = 1;
          this.params.pageSize = 10;
          this.centerDialogVisible = false;
          this.getPlateEdit();
          this.companyList();
        } else {
          return false;
        }
      });
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.companyList();
    },
    resetBtn() {
      // 筛选重置
      this.params = {
        pageNum: 1,
        pageSize: 10
        // name: "",
        // companyId: null,
        // roleId: null,
        // userDepartId: null,
      };
      this.companyList();
    },
    async companyList() {
      let params = { ...this.params };
      this.loading = true;
      let { data } = await companyList(params);
      this.loading = false;
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.tableData = data.records;
    },
    addEdit(row) {
      this.centerDialogVisible = true;
      if (row) {
        this.getCompanyPlateList();
        // 编辑获取详情
        this.tableRow = row;
        this.ruleFormAdd = {
          name: row.name,
          address: row.address,
          status: row.status + "",
          plateIds: row.plateId
        };
        this.params.companyId = row.id;
      } else {
        // 新增清空数据
        this.tableRow = "";
        this.ruleFormAdd = {
          name: "",
          address: "",
          status: "1",
          plateIds: []
        };
        if (this.$refs["ruleFormCom"]) {
          this.$refs["ruleFormCom"].resetFields();
        }
        this.getCompanyPlateList();
      }
    },
    async deleteCompany(id) {
      await deleteCompany({}, id);
      this.$vuex.dispatch("getMenuInfo");
      this.$message.error("删除成功");
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.companyList();
    },
    // 查全部 公司版块查询
    async getCompanyPlateList() {
      this.ruleFormAdd.plateIds = [];
      let params = { ...this.params2 };
      const { data } = await comPlate(params);
      this.comPlateList = data;
    },
    // 配置公司版块
    async getPlateEdit() {
      let params = { ...this.params2 };
      params.plateIds = this.ruleFormAdd.plateIds;
      const res = await plateEdit(params);
      if (res.code != 200) {
        return this.$message.error("新建失败");
      }
    },
    // 板块设置按钮
    async setCompany(row) {
      this.plateVisible = true;
      this.setComParams.plateIds = [];
      const { data } = await comPlate({ companyId: row.id });
      data.forEach((item) => {
        if (item.isChoose == 1) {
          this.setComParams.plateIds.push(item.plateId);
        }
      });
      this.setComParams.companyId = row.id;
      this.getCompanyPlateList();
    },
    async editTable() {
      let params = { ...this.setComParams };
      const res = await plateEdit(params);
      if (res.code != 200) {
        return this.$message.error("操作失败");
      }
      this.plateVisible = false;
      this.companyList();
      this.$message.success("操作成功");
    }
  }
};
</script>
<style lang="less" scoped>
.share-dialog {
  /deep/.el-dialog__footer {
    padding: 30px 0;
    text-align: center;
  }
}
.share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .share-blue-border {
    border: 1px #1890ff solid;
    border-radius: 4px;
    height: 50px;
    width: 90%;
    margin-bottom: 32px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &:nth-of-type(4) {
      padding: 4px 0px;
    }
    .share-code-item {
      height: 40px;
      line-height: 40px;
      width: 40px;
      text-align: center;
      background: #e6f3ff;
      border-radius: 4px;
      color: #1890ff;
    }
  }
  .share-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 24px;
  }
}
.companyList-container {
  text-align: left;
}
.dialog-input {
  width: 320px !important;
}
/deep/ .el-checkbox-group {
  display: inline-block;
  margin-right: 32px;
}
</style>
